"use client"

import { runCheckout } from '@/lib/stripe-utils'
import Link from 'next/link'
import { useState } from 'react'
import {MobileSidebar} from "@/app/(client)/(index)/components/index-mobile-sidebar";

export default function IndexHeader() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className={"top-0 sticky z-10 dark:bg-background bg-white/90"}>
            <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex items-center gap-x-4">
                    <Link href="/" className="-m-1.5 mt-1.5 p-1.5 text-lg font-bold leading-6 text-gray-900 dark:fill-white flex items-center gap-x-2">
                        <img src="/logo-full.svg" className={"h-8 dark:invert"} alt={"logo"}/>
                    </Link>
                    <div className={"hidden md:flex gap-x-8 font-semibold mt-1 text-md items-center ml-10"}>
                        <div data-umami-event="IndexHeader: Get_Started_BUTTON" onClick={(e)=>runCheckout(undefined)} className={"flex w-full hover:block! dark:hover:fill-white dark:fill-black fill-white hover:fill-black items-center hover:underline transition-all cursor-pointer"}>
                            <a>Get Started</a>
                        </div>
                        <Linkable link={"/#features"} text={"Features"}/>
                        <Linkable link={"/#pricing"} text={"Pricing"}/>
                        <Linkable link={"/#faq"} text={"FAQ"}/>
                    </div>
                </div>
                <div className="flex md:hidden">
                    <MobileSidebar/>
                </div>
                <div className="hidden md:flex mt-1 gap-x-6">
                    <div className="mt-1">
                        <Linkable link={"/login"} text={"Login"}/>
                    </div>
                    <button data-umami-event="IndexHeader: Sign_Up_BUTTON"  onClick={(e)=>runCheckout(undefined)} className={"hover:opacity-60 transition-all hover:animate-pulse font-normal text-md bg-emerald-300 text-black p-1 mt-0 px-3 rounded-lg "}>
                        Sign Up ―
                        <a className={"font-light italic ml-1"}>start uploading!</a>
                    </button>
                </div>
            </nav>
        </header>
        )
}


// @ts-ignore
const Linkable = ({ text, link }) => {
    return (
        <>
            <Link href={link}>
                <div className={"flex w-full hover:block! dark:hover:fill-white dark:fill-black fill-white hover:fill-black items-center hover:underline transition-all"}>
                    <p className={""}>{text}</p>
                </div>
            </Link>
        </>
        );
}

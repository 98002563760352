"use client"

import Link from "next/link"
import IndexHeader from "@/app/(client)/(index)/components/index-header";

export default function NotFound() {
    return <>
        <div className={" h-screen"}>
            <IndexHeader/>
                <div className="flex mt-48">
                    <div className="m-auto px-4 justify-center items-center max-w-xl text-center">
                        <Link href={"/"} className="mb-20 mx-auto h-10 transition-all peer lg:flex p-2.5 text-lg font-bold leading-6 text-gray-900 dark:text-white flex items-center gap-x-2">
                            <img src="/logo-full.svg" className={"dark:invert transition-all peer mx-auto"}/>
                        </Link>
                        <p className="font-semibold text-2xl">Lost && Not Found</p>
                        <p className="opacity-80 font-medium text-lg">Looks like we can't find that page. Maybe check the address again, try signing in first, or letting us know about the trouble.</p>
                        <p className="opacity-80 font-light text-sm mt-10">404_not_found</p>
                    </div>
                </div>
        </div>
    </>
}


"use client"

import axios from "axios";

export async function generateCheckout(email: string | undefined = undefined) {
    const env = process.env.NODE_ENV
    console.log(env)
    if(env !== "development"){
        return "/"
    }
    const response = await axios.post('/api/stripe/checkout' + (email ? "?email="+email : ""));
    if (response.status == 200) {
        return response.data.url;
    } else {
        throw Error()
    }
}

export function runCheckout(email: string | undefined = undefined): Promise<string|Error> {
    return generateCheckout(email)
    .then((e)=>{
        window.location.href=e;
        return e;
    })
    .catch((e)=>{

        return e
    })
}
"use client"

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import Link from "next/link";
import {Linkable} from "@/app/(client)/app/components/app-header";
import { useState } from "react";
import { useAuth } from "@/app/db/Auth";
import { runCheckout } from "@/lib/stripe-utils";


export function MobileSidebar() {
    
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    
    return (
        
        <>
        <Sheet open={mobileMenuOpen} onOpenChange={(e)=>setMobileMenuOpen(e)}>
            <button type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-white"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <svg className={"h-6 w-6 fill-black dark:fill-white"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </button>
            <SheetContent side={"right"}>
                <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                    <Link href="/" className="mx-auto pb-10 -m-1.5 mt-1.5 p-1.5 text-lg font-bold leading-6 text-gray-900 dark:text-white flex items-center gap-x-2">
                        <img src="/logo-full.svg" className={"h-8 dark:invert"} alt={"logo"}/>
                    </Link>
                    <div className={"text-center"} onClick={(e)=>setMobileMenuOpen(false)}>
                        <Linkable mobile={true} link={"/#pricing"} text={"Get Started"}/>
                        <Linkable mobile={true} link={"/#features"} text={"Features"}/>
                        <Linkable mobile={true} link={"/#pricing"} text={"Pricing"}/>
                        <Linkable mobile={true} link={"/#faq"} text={"FAQ"}/>
                    </div>
                    <button data-umami-event="IndexMobileSidebar: Sign_Up_BUTTON" onClick={(e)=>runCheckout(undefined)} className={"mt-4 hover:opacity-60 transition-all hover:animate-pulse font-normal text-md dark:bg-white dark:text-black bg-black text-white p-1 px-3 rounded-lg "}>
                    Sign Up
                    </button>
                    <Link data-umami-event="IndexMobileSidebar: Login_BUTTON" href={"/login"} className={"text-center mt-2 hover:opacity-60 transition-all hover:animate-pulse font-normal text-md p-1 px-3 rounded-lg "}>
                    Login
                    </Link>
                </nav>
            </SheetContent>
        </Sheet>
        </>
    )
}